import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css';
import apiClient from './axiosConfig';


function PanelUser() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [activeDevices, setActiveDevices] = useState([]);
    const [inactiveDevices, setInactiveDevices] = useState([]);
    const [inactiveCount, setInactiveCount] = useState(0);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = () => {
        const url = `https://gostlink.com/api/panel/users/getUserById?id=${localStorage.getItem('selected_person')}`;
        apiClient.post(url)
            .then(response => {
                setUserData(response.data || []);
                localStorage.setItem('selected_person_email', response.data.email);
                localStorage.setItem('selected_person_uid', response.data.uid);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    useEffect(() => {
        if (userData && userData.devices) {
            if (userData && userData.devices) {
                const active = userData.devices.filter(device => device.isActive);
                const inactive = userData.devices.filter(device => !device.isActive);
                setActiveDevices(active);
                setInactiveDevices(inactive);
                setInactiveCount(inactive.length);
            }
        }
    }, [userData]);

    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <p className='panel-block-title'>Информация о пользователе</p>
                    {userData ? (
                        <>
                            <div style={{ textAlign: 'left'}}>
                                <div className='panel-section'>
                                    <span><b>ID:</b> {userData.id}</span><br/>
                                    <span><b>Email:</b> {userData.email}</span><br/>
                                </div>

                                <div className='panel-section'>
                                    <span><b>Статус подписки:</b> </span>
                                    <span className={`panel-block-comment panel-block-comment-accent ${
                                        userData.subscription.status === 'EXPIRED' ? 'panel-block-comment-NB panel-block-comment-NB-sh' :
                                            userData.subscription.status === 'ACTIVE' ? 'panel-block-comment-OK panel-block-comment-OK-sh' :
                                                userData.subscription.status === 'CANCELED' ? 'panel-block-comment-So-So panel-block-comment-So-So-sh' :
                                                    userData.subscription.status === 'NOT_ACTIVE' ? 'panel-block-comment-NB panel-block-comment-NB-sh' : ''}`}
                                    >
                                        {userData.subscription.status === 'EXPIRED' ? 'Истекла' :
                                            userData.subscription.status === 'ACTIVE' ? 'Активна' :
                                                userData.subscription.status === 'CANCELED' ? 'Отменена' :
                                                    userData.subscription.status === 'NOT_ACTIVE' ? 'Отсутствует' :
                                                        userData.subscription.status}
                                    </span><br/>
                                    {userData.subscription.startDate && <><span><b>Начало подписки:</b> {userData.subscription.startDate}</span><br/></>}
                                    {userData.subscription.endDate && <><span><b>Окончание подписки:</b> {userData.subscription.endDate}</span><br/></>}
                                    <br/>
                                    <span><b>Первый платеж:</b> {userData.firstPayment} {userData.currency}</span><br/>
                                    <span><b>Постоянная стоимость:</b> {userData.subscription.totalCost} {userData.currency}</span><br/>
                                    <span><b>Первый платеж:</b> {userData.firstPaymentIsActive ? 'Оплачен' : 'Не оплачен'}</span><br/>
                                    <br/>
                                    <span><b>Промо активировано:</b> {userData.isPromoActivated ? 'Да' : 'Нет'}</span><br/>
                                    <span><b>Изначальная скидка:</b> {userData.discount * 100}%</span><br/>
                                    <span><b>Реферальная скидка:</b> {userData.referralDiscount * 100}%</span><br/>

                                    <br/>

                                    <span><b>Дополнительные устройства:</b> {userData.subscription.deviceCount}</span><br/>
                                </div>

                                <div className='panel-section'>
                                    <span>
                                        <b>Пригласительная ссылка:</b><br/>
                                        https://gostlink.com/?token={userData.invitationCode}
                                    </span><br/>
                                </div>
                            </div>

                            <div className='panel-button-flex-block' style={{marginTop: '10px'}}>
                                <button className="panel-button panel-button-for-block" onClick={() => {navigate('/panel-invited')}}>
                                    Приглашенные пользователи
                                </button>

                                <button className="panel-button panel-button-for-block" onClick={() => {navigate('/panel-receipts')}}>
                                    Чеки
                                </button>

                                <button className="panel-button panel-button-for-block" onClick={() => {navigate('/panel-logs')}}>
                                    Логи
                                </button>
                                {['ACTIVE', 'CANCELED'].includes(userData.subscription.status) && (
                                    <button className="panel-button panel-button-for-block" onClick={() => {navigate('/panel-subscription')}}>
                                        Управление подпиской
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <p>Loading data...</p>
                    )}
                </div>

                {userData && (
                    ['ACTIVE', 'CANCELED'].includes(userData.subscription.status) && (
                        <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal'}}>
                            <p className='panel-block-title'>Устройства</p>
                            {activeDevices.length > 0 ? (
                                <div>
                                    {activeDevices.map((device, index) => (
                                        <div key={index} className="panel-section">
                                            <div style={{ textAlign: 'left' }}>
                                                <span><b>ID:</b> </span><span>{device.deviceId}</span><br />
                                                <span><b>Название:</b> </span><span>{device.deviceName}</span><br />
                                                <span><b>Платформа:</b> </span><span>{device.platform}</span><br />
                                                <span><b>Дата создания:</b> </span><span>{device.created}</span><br />
                                                <span><b>Дата активации:</b> </span><span>{device.firstActivation}</span><br />
                                            </div>
                                            {/*<button className="panel-button">Деактивация</button>*/}
                                        </div>
                                    ))}
                                    {inactiveCount > 0 && (
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{marginLeft: '10px'}}>Неактивированных устройств: {inactiveCount}</span>
                                            {/*{inactiveDevices.map((device, index) => (*/}
                                            {/*    <div key={index} className="panel-section" style={{ textAlign: 'left' }}>*/}
                                            {/*        <span><b>ID:</b> </span><span>{device.deviceId}</span><br />*/}
                                            {/*        <span><b>Название:</b> </span><span>{device.deviceName}</span><br />*/}
                                            {/*        <span><b>Платформа:</b> </span><span>{device.platform}</span><br />*/}
                                            {/*        <span><b>Дата создания:</b> </span><span>{device.created}</span><br />*/}
                                            {/*        <span><b>Дата активации:</b> </span><span>{device.firstActivation}</span><br />*/}
                                            {/*    </div>*/}
                                            {/*))}*/}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <span>У пользователя нет активных устройств<br/>но из хорошего - у него есть ВПН</span>
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default PanelUser;
