import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import apiClient from "./axiosConfig";

function PanelSearch() {
    const navigate = useNavigate();
    const [SearchData, setSearchData] = useState(null);
    const [SearchString, setSearchString] = useState("");

    const [currentPage, setCurrentPage] = useState(0);  // Текущая страница
    const [totalPages, setTotalPages] = useState(0);  // Общее количество страниц

    useEffect(() => {
        fetchSearchData();
    }, [currentPage]);

    const fetchSearchData = () => {
        const url = `https://gostlink.com/api/panel/users/search?page=${currentPage}&size=10&sortBy=id&emailPart=${SearchString}`;
        apiClient.post(url)
            .then(response => {
                setSearchData(response.data.content || []);
                setTotalPages(response.data.page.totalPages);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="panel-container">
            <div className="panel-block">
                <span><strong>Поиск нужного профиля</strong></span><br/>
                <div style={{ display: 'flex', width: '100%' }}>
                    <input
                        type="text"
                        inputMode="text"
                        placeholder="Введите почту клиента..."
                        value={SearchString}
                        onChange={(e) => {
                            let newValue = e.target.value;
                            setSearchString(newValue);
                        }}
                        onKeyDown={() => fetchSearchData()}
                        style={{width: '100%'}}
                    />
                    <button className="panel-button"
                            onClick={() => fetchSearchData()}>Поиск
                    </button>
                </div>
            </div>

            <div className="panel-block">
                {SearchData ? (
                    SearchData.length > 0 ? (
                        <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            {SearchData.map(user => (
                                <div className="panel-block-list" key={user.id} style={{textAlign: 'left'}}
                                     onClick={() => {
                                         localStorage.setItem('selected_person', user.id);
                                         navigate('/panel-user');}}
                                >
                                    <span><b>{user.id}</b> - {user.email}</span><br/>
                                    <span><b>Статус подписки:</b> </span>
                                    <span className={`panel-block-comment panel-block-comment-accent ${
                                        user.subscription.status === 'EXPIRED' ? 'panel-block-comment-NB panel-block-comment-NB-sh' : 
                                            user.subscription.status === 'ACTIVE' ? 'panel-block-comment-OK panel-block-comment-OK-sh' : 
                                                user.subscription.status === 'CANCELED' ? 'panel-block-comment-So-So panel-block-comment-So-So-sh' : 
                                                    user.subscription.status === 'NOT_ACTIVE' ? 'panel-block-comment-NB panel-block-comment-NB-sh' : ''}`}
                                    >
                                        {user.subscription.status === 'EXPIRED' ? 'Истекла' :
                                            user.subscription.status === 'ACTIVE' ? 'Активна' :
                                                user.subscription.status === 'CANCELED' ? 'Отменена' :
                                                    user.subscription.status === 'NOT_ACTIVE' ? 'Отсутствует' :
                                                        user.subscription.status}
                                    </span>
                                </div>
                            ))}
                            {totalPages > 1 && (
                                <div className="navigation-list" style={{ }}>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 0}
                                        style={{ opacity: currentPage === 0 ? 0.5 : 1 }}
                                    >
                                        <FaAngleLeft size={15} color='rgba(0, 0, 0, 0.7)' style={{marginRight: '2px'}}/>
                                    </button>
                                    <span>{currentPage + 1} / {totalPages}</span>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages - 1}
                                        style={{ opacity: currentPage === totalPages - 1 ? 0.5 : 1 }}
                                    >
                                        <FaAngleRight size={15} color='rgba(0, 0, 0, 0.7)' style={{marginLeft: '2px'}}/>
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <span className="text-tips" style={{fontSize: '12pt'}}>ничего не найдено</span>
                    )
                ) : (
                    <p>Loading data...</p>
                )}
            </div>

            <div className="panel-block">
                <div className="panel-block-list"
                        onClick={() => {navigate('/panel-promo');}}>
                    <b>Промокоды, промокоды...</b>
                </div>
            </div>

        </div>
    );
}

export default PanelSearch;
