import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import './PanelStyle.css';
import apiClient from './axiosConfig';


function PanelSubscription() {
    const [serverMessage, setServerMessage] = useState("");
    const [blockClass, setBlockClass] = useState("");
    const handleRefund = async () => {
        const url = "https://gostlink.com/api/panel/refund";

        const requestData = {
            email: localStorage.getItem("selected_person_email"),
            uid: localStorage.getItem("selected_person_uid"),
        };

        apiClient.post(url, requestData)
            .then(response => {
                if (response.data.Success) {
                    setServerMessage(response.data.Message);
                    setBlockClass("panel-block-comment-OK");
                } else {
                    setServerMessage(response.data.Message);
                    setBlockClass("panel-block-comment");
                }
                setTimeout(() => {
                    setServerMessage("");
                }, 7000);
            })
            .catch(error => {
                setServerMessage("Произошла ошибка при выполнении запроса.");
                setBlockClass("panel-block-comment-NB");
                setTimeout(() => {
                    setServerMessage("");
                }, 7000);
                console.error("Fetch error:", error);
            });
    };


    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Управление подпиской</strong></span><br/>

                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal'}}>
                    <p className='panel-block-title'>Уменьшить количесво устройств</p>
                    <div className='panel-section'>
                        <p className='panel-block-comment'>
                            Пока такой опции нет, но можешь на кнопочку понажимать!
                        </p>
                        <button className="panel-button">
                            Убрать одно устройство
                        </button>
                    </div>
                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal'}}>
                    <p className='panel-block-title'>Отменить подписку пользователя</p>
                    <div className='panel-section'>
                        <p className='panel-block-comment panel-block-comment-NB'>
                            Доступ к сервису будет мгновенно приостановлен, средства будут возвращены в ближайшее время (до 60 дней).
                        </p>
                        {serverMessage && (
                            <p className={`panel-block-comment ${blockClass}`}>
                                {serverMessage}
                            </p>
                        )}
                        <button className="panel-button" onClick={handleRefund}>
                            Сделать возврат
                        </button>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default PanelSubscription;
