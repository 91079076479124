import React, { useEffect, useState } from 'react';
import '../languages/i18n';
import './PanelStyle.css';
import { toast } from "react-toastify";
import apiClient from './axiosConfig';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";


function PanelReceipts() {
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);  // Текущая страница
    const [totalPages, setTotalPages] = useState(0);  // Общее количество страниц

    useEffect(() => {
        fetchReceipts();
    }, [currentPage]);

    const fetchReceipts = () => {
        const userId = localStorage.getItem('selected_person');
        const url = `https://gostlink.com/api/panel/checks?userId=${userId}&page=${currentPage}&size=3`;
        apiClient.post(url)
            .then(response => {
                setUserData(response.data.content || []);
                setTotalPages(response.data.page.totalPages);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Чеки об оплате</strong></span><br />
                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal' }}>
                    {userData ? (
                        userData.length > 0 ? (
                            <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {userData.map((receipt, index) => (
                                    <div key={index} className="panel-section">
                                        {/*<div key={index} className="panel-section-chek">*/}
                                        <div style={{ textAlign: 'left' }}>
                                            <span><b>Дата:</b> {new Date(receipt.dateTime).toLocaleString()}</span><br/>
                                            <span><b>Email:</b> {receipt.accountId}</span><br/>
                                            <span><b>Сумма:</b> {receipt.amount} RUB</span><br/>
                                            <span><b>Описание:</b> {receipt.description}</span><br/>
                                            <span><b>IP адрес:</b> {receipt.ipAddress}</span><br/>
                                            <span><b>Статус:</b> {receipt.status}</span><br/>
                                            <span><b>ID транзакции:</b> {receipt.transactionId}</span><br/>
                                            <span><b>ID подписки:</b> {receipt.subscriptionId}</span><br/>
                                            <span><b>Гарантия:</b> {receipt.isWarrantyOver ? "Действует" : "Истекла"}</span><br/>
                                        </div>
                                    </div>
                                ))}
                                {totalPages > 1 && (
                                    <div className="navigation-list" style={{ }}>
                                        <button
                                            onClick={handlePrevPage}
                                            disabled={currentPage === 0}
                                            style={{ opacity: currentPage === 0 ? 0.5 : 1 }}
                                        >
                                            <FaAngleLeft size={15} color='rgba(0, 0, 0, 0.7)' style={{marginRight: '2px'}}/>
                                        </button>
                                        <span>{currentPage + 1} / {totalPages}</span>
                                        <button
                                            onClick={handleNextPage}
                                            disabled={currentPage === totalPages - 1}
                                            style={{ opacity: currentPage === totalPages - 1 ? 0.5 : 1 }}
                                        >
                                            {/*<IoCaretForwardOutline/>*/}
                                            <FaAngleRight size={15} color='rgba(0, 0, 0, 0.7)' style={{marginLeft: '2px'}}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>Пусто :(</p>
                        )
                    ) : (
                        <p>Loading data...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PanelReceipts;
