import axios from 'axios';

// Создаем экземпляр `axios`
const apiClient = axios.create({
    baseURL: 'https://gostlink.com/api',
});

apiClient.interceptors.request.use(
    (config) => {
        if (!localStorage.getItem('accessToken')) {
            window.location.href = '/panel-login';
        } else {
            const accessToken = localStorage.getItem('accessToken');
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.error(error);
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refreshToken');
                if (!refreshToken) {
                    window.location.href = '/panel-login';
                    return Promise.reject(new Error('Refresh token отсутствует'));
                }

                const response = await axios.post(
                    'https://gostlink.com/api/auth/refreshToken',
                    { refreshToken: refreshToken });
                const newAccessToken = response.data.accessToken;
                localStorage.setItem('accessToken', newAccessToken);

                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

                return apiClient(originalRequest);
            } catch (tokenRefreshError) {
                console.error('Ошибка обновления токена:', tokenRefreshError);
                window.location.href = '/panel-login';
                return Promise.reject(tokenRefreshError);
            }
        } else {
            window.location.href = '/panel-login';
        }
        return Promise.reject(error);
    }
);

export default apiClient;
