import React, { useEffect, useState } from 'react';
import '../languages/i18n';
import Pricing from "./elements/Pricing";
import {useNavigate} from "react-router-dom";
import User from "./elements/User";
import {useTranslation} from "react-i18next";


function Shop() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // Dynamically load CloudPayments widget script
        const script = document.createElement('script');
        script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
    
    return (
        <div className="container-main">
            <User />
            <Pricing/>
        </div>
    );
}

export default Shop;
