import React, { useEffect, useState } from 'react';
import '../languages/i18n';
import './PanelStyle.css';
import { toast } from "react-toastify";
import apiClient from './axiosConfig';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";

function PanelInvited() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);  // Текущая страница
    const [totalPages, setTotalPages] = useState(0);  // Общее количество страниц

    useEffect(() => {
        fetchInvited();
    }, [currentPage]);

    const fetchInvited = () => {
        const userId = localStorage.getItem('selected_person');
        const url = `https://gostlink.com/api/panel/invitations/getInviteesByInviterId?userId=${userId}&size=10&page=0&sortBy=created&sortDirection=DESC`;
        apiClient.post(url)
            .then(response => {
                setUserData(response.data.invitations.content || []);
                setTotalPages(response.data.page.totalPages);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Приглашенные пользователи</strong></span><br/>
                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal' }}>
                    {userData ? (
                        userData.length > 0 ? (
                            <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {userData.map((guest, index) => (
                                    // <div key={index} className="panel-section">
                                    <div className='panel-section' key={index} style={{ textAlign: 'left' }}>
                                        <span><b>ID:</b> {guest.inviteeId}</span><br/>
                                        <span><b>Email:</b> {guest.inviteeEmail}</span><br/>
                                        <span><b>Создан:</b> {guest.created}</span><br/>
                                        <span><b>Начало подписки:</b> {guest.discountStartDate}</span><br/>
                                        <span><b>Окончание подписки:</b> {guest.subscriptionEndDate}</span><br/>
                                        <span><b>Статус подписки:</b> </span>
                                        <span className={`panel-block-comment panel-block-comment-accent ${guest.hasSubscription ? 'panel-block-comment-OK panel-block-comment-OK-sh' : 'panel-block-comment-NB panel-block-comment-NB-sh'}`}>
                                                {guest.hasSubscription ? 'Активна' : 'Отсутствует'}
                                        </span><br/>
                                    </div>
                                ))}
                                {totalPages > 1 && (
                                    <div className="navigation-list" style={{ }}>
                                        <button
                                            onClick={handlePrevPage}
                                            disabled={currentPage === 0}
                                            style={{ opacity: currentPage === 0 ? 0.5 : 1 }}
                                        >
                                            <FaAngleLeft size={15} color='rgba(0, 0, 0, 0.7)' style={{marginRight: '2px'}}/>
                                        </button>
                                        <span>{currentPage + 1} / {totalPages}</span>
                                        <button
                                            onClick={handleNextPage}
                                            disabled={currentPage === totalPages - 1}
                                            style={{ opacity: currentPage === totalPages - 1 ? 0.5 : 1 }}
                                        >
                                            {/*<IoCaretForwardOutline/>*/}
                                            <FaAngleRight size={15} color='rgba(0, 0, 0, 0.7)' style={{marginLeft: '2px'}}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>Пусто :(</p>
                        )
                    ) : (
                        <p>Loading data...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PanelInvited;
