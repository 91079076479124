import './css/App.css';
import './css/Main.css';
import './css/PromoNew.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from "./js/StartPage.js";
import Shop from "./js/Shop.js";
import Background from "./background/Background";
import YAuth from "./registration/YAuth";
import ShopVMobile from "./js/ShopVMobile";
import Profile from "./js/Profile";
import Promo from "./js/Promo";
import PromoNew from "./js/PromoNew";
import Dolor from "./js/Dolor";
import YAuthMobile from "./registration/YAuthMobile";
import Signature from "./js/elements/Signature";
import ProfileVMobile from "./js/ProfileVMobile";
import PanelLogin from "./panel/PanelLogin";
import PanelSearch from "./panel/PanelSearch";
import PanelUser from "./panel/PanelUser";
import PanelReceipts from "./panel/PanelReceipts";
import PanelInvited from "./panel/PanelInvited";
import PanelLogs from "./panel/PanelLogs";
import './languages/i18n';
import PanelPromo from "./panel/PanelPromo";
import PanelSubscription from "./panel/PanelSubscription";
import StartPageNew from "./js/StartPageNew";
import Login from "./js/Login";

function App() {

    return (
        <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
                <div>
                    <Background/>
                </div>
                <Router>
                    <Routes>
                        <Route path="/promo" element={<Promo/>}/>
                        <Route path="/promo-new" element={<PromoNew/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/mobileShop" element={<ShopVMobile/>}/>
                        <Route path="/mobileProfile" element={<ProfileVMobile/>}/>
                        <Route path="/yauth" element={<YAuth/>}/>
                        <Route path="/yauth-mobile" element={<YAuthMobile/>}/>
                        <Route path="/dolor" element={<Dolor/>}/>

                        {/*<Route path="/start" element={<StartPageNew/>}/>*/}
                        <Route path="/login" element={<Login/>}/>

                        <Route path="/panel-login" element={<PanelLogin/>}/>
                        <Route path="/panel-search" element={<PanelSearch/>}/>
                        <Route path="/panel-user" element={<PanelUser/>}/>
                        <Route path="/panel-receipts" element={<PanelReceipts/>}/>
                        <Route path="/panel-invited" element={<PanelInvited/>}/>
                        <Route path="/panel-logs" element={<PanelLogs/>}/>
                        <Route path="/panel-promo" element={<PanelPromo/>}/>
                        <Route path="/panel-subscription" element={<PanelSubscription/>}/>

                        <Route path="/shop" element={<Shop/>}/>
                        <Route path="/tt" element={<StartPageNew/>}/>
                        <Route path="/yt" element={<StartPageNew/>}/>
                        <Route path="" element={<StartPageNew/>}/>
                        <Route path="/" element={<StartPageNew/>}/>
                    </Routes>
                </Router>
            </div>
            <Signature/>
        </div>
    );
}

export default App;
