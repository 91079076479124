import React, { useEffect, useState, useRef } from "react";
import "../css/StartPage.css";
import { GoChevronRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import logo from "../images/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {BsStars} from "react-icons/bs";
import {IoAlertCircleOutline} from "react-icons/io5";
import {HiOutlineArrowSmallRight} from "react-icons/hi2";

function StartPage() {
    const { t } = useTranslation();
    const descriptions = t("Start-page.Description", { returnObjects: true });
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const features = t('Pricing.Features', { returnObjects: true });
    const [shopData, setShopData] = useState([]);

    useEffect(() => {
        const requestData = {
            uid: "",
            email: "",
        };
        fetchShopData(requestData);
    }, []);

    const fetchShopData = (userData) => {
        fetch(`https://gostlink.com/api/shop/getShop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then(response => {
                console.log(response);
                return response.text();
            })
            .then(text => {
                console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    setShopData(data);
                } else {
                    navigate(`/`);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");

        if (token) {
            localStorage.setItem("promo", token);
        }
    }, [location.search]);

    const handleToggle = (index) => {
        if (index !== activeIndex) {
            setActiveIndex(index);
        }
    };

    return (
        <div className="page-block">
            <div id="header-site">
                <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
                    <h1 id="title-site">GOSTLINK</h1>
                    <img src={logo} id="logo-site" />
                </div>
                <span className="description-site-1">{t("Start-page.Description-site")}</span>
            </div>
            <div className="content">
                <div className="sale-block-content sale-block-content-mobile">
                    <a href="#sale-block">
                        <button className="sale-block-button_pay">
                            Цены и тарифы
                        </button>
                    </a>
                    <button className="sale-block-button_enter" onClick={() => {navigate('/login')}}>
                        Уже есть подписка? Войти
                    </button>
                </div>
                <div id="description">
                    {descriptions.map((item, index) => (
                        <AccordionItem
                            key={index}
                            title={item.title}
                            text={item.text}
                            isActive={index === activeIndex}
                            onClick={() => handleToggle(index)}
                        />
                    ))}
                </div>

                <div id="sale-block">
                    <div className="sale-block-content">
                        {shopData.success ? (
                            <div className="card">
                                <div className="card-header">
                                    <span className="discount-badge" style={{backgroundColor: '#e6f4ea', color: '#2f855a'}}>
                                        {t('Pricing.Sale')} {shopData.discount}%
                                    </span>
                                    <h3 className="card-title">
                                        {t('Pricing.Type')}
                                    </h3>
                                    {shopData.firstPayment === shopData.monthlyPayment ? (
                                        <div className="price">
                                            <span className="price-value">
                                                {shopData.firstPayment}
                                            </span>
                                            <span className="price-symbol">
                                                {t('Pricing.Symbol-month')}
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="price">
                                                <span className="price-value">
                                                    {t('Pricing.First-prise')} {shopData.firstPayment}
                                                    <span className="price-symbol">
                                                        {t('Pricing.Symbol')}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="price">
                                                <span className="price-value" style={{fontSize: '20px'}}>
                                                    {t('Pricing.Constant-prise')} {shopData.monthlyPayment}
                                                    <span className="price-symbol" style={{fontSize: '14px'}}>
                                                        {t('Pricing.Symbol-month')}
                                                    </span>
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    <div className="price-old">{shopData.strikethroughPrice} {t('Pricing.Symbol-month')}</div>
                                </div>
                                <div className="card-body">
                                    <table>
                                        <tbody>
                                        {features.map((feature, index) => (
                                            <tr key={index}>
                                                <td><BsStars className="icon" /></td>
                                                <td><p style={{margin: '0 0 2px 0'}} className="feature">{feature.text}</p></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <span>
                                {t('Pricing.Loading')}
                            </span>
                        )}

                        <button className="sale-block-button_pay" onClick={() => {navigate('/login')}}>
                            Приобрести подписку
                        </button>
                        <button className="sale-block-button_enter sale-block-button_enter-desctop" onClick={() => {navigate('/login')}}>
                            Уже есть подписка? Войти
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

const AccordionItem = ({ title, text, isActive, onClick }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (isActive) {
            setHeight(contentRef.current.scrollHeight);
        } else {
            setHeight(0);
        }
    }, [isActive]);

    return (
        <div className="description-element">
            <div className="title-description" onClick={onClick}>
                <h1>{title}</h1>
                <GoChevronRight className={`icon ${isActive ? "rotated" : ""}`} />
            </div>
            <div className="description-text"
                 style={{
                     maxHeight: `${height}px`,
                     transition: "max-height 0.5s ease, opacity 0.5s ease",
                     opacity: isActive ? 1 : 0,
                 }}
                 ref={contentRef}
            >
                {/*<span dangerouslySetInnerHTML={{ __html: t('Promo.Title') }} />*/}
                <p style={{ marginBottom: "0" }}>{text}</p>
            </div>
        </div>
    );
};

export default StartPage;
