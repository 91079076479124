import React, { useEffect, useState } from 'react';

const Star = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 0L4.36364 4.36364L0 6L4.36364 7.63636L6 12L7.63636 7.63636L12 6L7.63636 4.36364L6 0Z"
            fill="white"
        />
    </svg>
);

const Circle = () => (
    <svg width="12" height="12" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="10" fill="white" />
    </svg>
);

const StarBatch = ({ stars, circles, duration }) => (
    <div
        className="star-batch"
        style={{
            animationDuration: `${duration}s`,
        }}
    >
        {stars.map((star, index) => (
            <div
                key={`star-${index}`}
                className="star"
                style={{
                    position: 'absolute',
                    top: `${star.y}%`,
                    left: `${star.x}%`,
                }}
            >
                <Star />
            </div>
        ))}
        {circles.map((circle, index) => (
            <div
                key={`circle-${index}`}
                className="circle"
                style={{
                    position: 'absolute',
                    top: `${circle.y}%`,
                    left: `${circle.x}%`,
                }}
            >
                <Circle />
            </div>
        ))}
    </div>
);

const StarStars = () => {
    const [batches, setBatches] = useState([]);

    const createShapes = () => {
        return Array.from({ length: 3 }).map(() => ({
            x: Math.random() * 100,
            y: Math.random() * 100,
        }));
    };

    const createBatches = () => {
        const screenWidth = window.innerWidth;
        const starCount = screenWidth / 200;
        return Array.from({ length: starCount }).map(() => ({
            id: Math.random(),
            stars: createShapes(),
            circles: createShapes().concat(createShapes()),
            duration: 6 + Math.random() * 4,
        }));
    };

    useEffect(() => {
        const newBatches = createBatches();
        setBatches(newBatches);

        const intervals = newBatches.map(batch =>
            setInterval(() => {
                setBatches(currentBatches =>
                    currentBatches.map(b =>
                        b.id === batch.id
                            ? { ...b, stars: createShapes(), circles: createShapes() }
                            : b
                    )
                );
            }, batch.duration * 6000)
        );

        return () => intervals.forEach(clearInterval);
    }, []);

    return (
        <div className="star-stars">
            {batches.map(batch => (
                <StarBatch key={batch.id} stars={batch.stars} circles={batch.circles} duration={batch.duration} />
            ))}
            <style>{`
                .star-stars {
                    position: relative;
                    width: 100vw;
                    height: 100vh;
                    overflow: hidden;
                }
                .star-batch {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    animation: fade 20s ease-in-out infinite;
                }
                .star, .circle {
                    opacity: 0;
                    animation: fade 20s ease-in-out infinite;
                }
                @keyframes fade {
                    0%, 100% { opacity: 0; }
                    50% { opacity: 1; }
                }
            `}</style>
        </div>
    );
};

export default StarStars;
