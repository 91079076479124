// analytics.js

import ReactGA from "react-ga4";

export const eventGoogleAnalytics = (action, category, label, value) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value,
    });
};

export const logPurchaseEvent = (transactionId, value, currency, items) => {
    ReactGA.event({
        category: "ecommerce",
        action: "purchase",
        label: `Transaction ${transactionId}`,
        value: value,
        items: items.map(item => ({
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            quantity: item.quantity,
            currency: currency,
        })),
    });
};


