import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en.json';
import translationRU from './ru.json';
import translationZH from './zh.json';
import translationKK from './kk.json';

i18n
    .use(LanguageDetector) // используем LanguageDetector для автоматического определения языка
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            ru: {
                translation: translationRU
            },
            zh: {
                translation: translationZH
            },
            kk: {
                translation: translationKK
            }
        },
        fallbackLng: 'en',
        detection: {
            order: ['localStorage', 'cookie', 'navigator'],
            caches: ['localStorage', 'cookie']
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;