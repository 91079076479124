import React, {useRef, useState} from 'react';
import '../../css/Signature.css';
import '../../languages/i18n';
import 'react-toastify/dist/ReactToastify.css';
import {FaApple, FaDesktop, FaGhost} from "react-icons/fa";
import {IoLogoAndroid} from "react-icons/io";
import '../../languages/i18n';
import {toast} from "react-toastify";
import {ImCross} from "react-icons/im";
import {FaPlus} from "react-icons/fa6";
import {useTranslation} from "react-i18next";


function Device({ id, platform, deviceName, deviceId, firstActivation, setDeviceData, setAvailableDevices }) {

    const [progress, setProgress] = useState(0);
    const holdTimer = useRef(null);
    const {t} = useTranslation();

    const handleMouseDown = () => {
        const startTime = Date.now();

        holdTimer.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const duration = 2000;

            if (elapsedTime >= duration) {
                clearInterval(holdTimer.current);
                setProgress(1000);
                fetchDetachDevice(id, deviceName);
                return;
            }
            const linearProgress = (elapsedTime / duration) * 100;
            setProgress(linearProgress);
        }, 16);
    };

    const handleMouseUpOrLeave = () => {
        if (holdTimer.current) {
            clearInterval(holdTimer.current);
            holdTimer.current = null;
        }
        setProgress(0);
    };


    const fetchDetachDevice = (id) => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
            deviceId: id,
        };

        fetch(`https://gostlink.com/api/profile/detachDevice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                const data = JSON.parse(text);
                if (data.success) {
                    setDeviceData(data.devices);
                    setAvailableDevices(data.availableDevices);
                    console.log(data)
                } else {
                    toast('Не удалось открепить устройство.')
                }
            })
            .catch((error) => {
                toast('Не удалось открепить устройство.');
                console.log(error);
            });
    };

    const getPlatformIcon = (platform) => {
        switch (platform.toLowerCase()) {
            case 'android':
                return <IoLogoAndroid size='100%'/>;
            case 'ios':
                return <FaApple size='70%' />;
            case 'desktop':
                return <FaDesktop size='70%' />;
            default:
                return <FaGhost size='60%' />;
        }
    };

    const formatDateTime = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} - ${hours}:${minutes}`;
    };

    return (
        <div className="device-box device-box-button" onMouseLeave={handleMouseUpOrLeave}
             style={{position: 'relative', overflow: 'hidden', userSelect: 'none', cursor: 'pointer', paddingBottom: '15px'}}
             onMouseDown={() => handleMouseDown()}
             onMouseUp={handleMouseUpOrLeave}
             onTouchStart={() => handleMouseDown()}
             onTouchEnd={handleMouseUpOrLeave}>
            <div className="progress-bar"
                 style={{
                     width: `${progress}%`,
                     height: '100%',
                     backgroundColor: 'rgba(27, 39, 53, 0.3)',
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     zIndex: 0,
                     transition: 'width 0.04s linear'
                 }}
            ></div>
            <div className="device-flex" style={{position: 'relative', zIndex: 1}}>
                <div className="device-icon">{getPlatformIcon(platform)}</div>
                <div className="device-info">
                    <span className="device-title">
                        {deviceName.length > 20 ? `${deviceName.slice(0, 17)}...` : deviceName} - {deviceId.slice(0, 16)}
                    </span>
                    <br/>
                    <span className="device-subtext">
                         {formatDateTime(firstActivation)}
                    </span>
                    <span className="block-progress-signature">{t('Profile.Signature-devices')}</span>
                </div>
                {/*<div className="device-icon-cross">*/}
                {/*    <FaPlus size='60%' style={{rotate: '45deg'}}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Device;




