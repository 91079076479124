import React, {useState, useEffect, useRef} from 'react';
import '../css/App.css';
import '../languages/i18n';
import {FaApple} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from './elements/User';
import {IoIosArrowForward, IoLogoAndroid} from "react-icons/io";
import {MdDiscount} from "react-icons/md";
import {useTranslation} from "react-i18next";
import Device from "./elements/Device";
import { PulseLoader } from 'react-spinners';
import '../css/Device.css';

function Profile() {
    const navigate = useNavigate();
    const [deviceData, setDeviceData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [availableDevices, setAvailableDevices] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchLoginData(requestData);
    }, []);

    useEffect(() => {
        if (userData.subscriptionStatus === "EXPIRED" || userData.subscriptionStatus === "NOT_ACTIVE") {
            navigate('/shop');
        }
    }, [userData]);

    const fetchLoginData = (requestData) => {
        fetch(`https://gostlink.com/api/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                if (text) {
                    const data = JSON.parse(text);
                    if (data.success) {
                        localStorage.setItem('expirationDate', data.userInfo.expirationDate);
                        localStorage.setItem('subscriptionIsActive', data.userInfo.subscriptionIsActive);
                        setUserData(data.userInfo)
                        setDeviceData(data.devices);
                        setAvailableDevices(data.availableDevices);
                        console.log(data)
                    } else {
                        // Handle failure
                    }
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                // Handle error
            });
    };

    const fetchAddDevice = () => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email')
        };

        fetch(`https://gostlink.com/api/profile/addDevice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                const data = JSON.parse(text);
                if (data.success) {

                    const requestData = {
                        uid: localStorage.getItem('uid'),
                        email: localStorage.getItem('email'),
                    };
                    fetchLoginData(requestData);

                } else {
                    navigate(`/`);
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    return (
        <div className="container-main">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />
            <User/>
            {userData.subscriptionStatus === "ACTIVE" || userData.subscriptionStatus === "CANCELED" ? (
                <div style={{width: '100%'}}>
                    {userData.subscriptionIsActive ? (
                        <div style={{width: '100%', cursor: 'pointer'}}>
                            <div className="device-box device-box-button" onClick={() => navigate('/promo-new')}
                                 style={{
                                     border: "solid 1px white",
                                     boxSizing: "border-box",
                                     boxShadow: "0 0 5px #FF00AAAA, 0 0 10px #DC0092AA, 0 0 20px #910060AA, 0 0 40px #910060AA, inset 0 0 20px #91006088"}}>
                                <div className="device-flex" >
                                    <div className="device-icon">
                                        <MdDiscount size='80%'/>
                                    </div>
                                    <div className="device-info">
                                        <span className="device-title" style={{fontStyle: 'italic'}}>
                                            {t('Profile.Free-service')}
                                        </span> <br/>
                                        <span className="device-subtext" style={{fontStyle: 'italic'}}>
                                            {t('Profile.Current-discount')} {userData.discount}%
                                        </span>
                                    </div>
                                    <div className="device-icon">
                                        <IoIosArrowForward size='80%'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {/*{availableDevices > 0 ? (*/}
                        <div className="device-box" style={{paddingBottom: '30px'}}>
                            <span className="device-title" style={{textAlign: 'center'}}>
                                {t('Profile.Download')}
                            </span>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '15px', marginTop: '10px'}}>
                                {/*TODO: add links to install files*/}
                                <IoLogoAndroid className='App-download' size={56} onClick={() => window.open('https://gostlink.com/api/get/apps/gostlink.apk', '_blank')}/>
                                {/*<FaMicrosoft size={48} className='App-download' color="#777777" onClick={() => toast(t('Profile.Under-development'))}/>*/}
                                <FaApple size={48} className='App-download' opacity='0.7' onClick={() => toast(t('Profile.Under-development'))}/>
                                {/*<FaLinux size={48} className='App-download' color="#777777" onClick={() => toast(t('Profile.Under-development'))}/>*/}
                                {/*<SiMacos size={60} className='App-download' color="#777777" onClick={() => toast(t('Profile.Under-development'))}/>*/}
                            </div>
                            <hr className='full-line-black'/>
                            <span className="device-subtext" style={{color: 'black', fontStyle: 'italic', position: 'absolute', bottom: '5px'}}>
                                {t('Profile.Unactivated-devices', { count: availableDevices })}
                            </span>
                        </div>
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}

                    {/*{deviceData.length > 0 ? (*/}
                        <ul style={{listStyleType: 'none', margin: '0', padding: '0', width: '100%'}}>
                            {deviceData.map((device) => (
                                <li key={device.id} className="device-item" style={{width: '100%'}}>
                                    <Device
                                        id={device.id}
                                        platform={device.platform}
                                        deviceName={device.deviceName}
                                        deviceId={device.deviceId}
                                        firstActivation={device.firstActivation}
                                        setDeviceData={setDeviceData}
                                        setAvailableDevices={setAvailableDevices}
                                    />
                                </li>
                            ))}
                        </ul>
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}

                    {/*{availableDevices === 0 && availableDevices < 5 ? (*/}
                    {/*    <div className="device-box device-box-button" onMouseLeave={handleMouseUpOrLeave}*/}
                    {/*         style={{position: 'relative', overflow: 'hidden', userSelect: 'none', paddingBottom: '15px'}}*/}
                    {/*         onMouseDown={() => handleMouseDown()}*/}
                    {/*         onMouseUp={handleMouseUpOrLeave}*/}
                    {/*         onTouchStart={() => handleMouseDown()}*/}
                    {/*         onTouchEnd={handleMouseUpOrLeave}>*/}
                    {/*        <div className="progress-bar"*/}
                    {/*             style={{*/}
                    {/*                 width: `${progress}%`,*/}
                    {/*                 height: '100%',*/}
                    {/*                 backgroundColor: 'rgba(27, 39, 53, 0.3)',*/}
                    {/*                 position: 'absolute',*/}
                    {/*                 top: 0,*/}
                    {/*                 left: 0,*/}
                    {/*                 zIndex: 0,*/}
                    {/*                 transition: 'width 0.04s linear'*/}
                    {/*             }}*/}
                    {/*        ></div>*/}
                    {/*        <div className="device-flex" >*/}
                    {/*            <div className="device-icon">*/}
                    {/*                <FaPlus size='60%'/>*/}
                    {/*            </div>*/}
                    {/*            <div className="device-info" >*/}
                    {/*                <span className="device-title" style={{fontStyle: 'italic'}}>*/}
                    {/*                    {t('Profile.Add-device')}*/}
                    {/*                </span><br/>*/}
                    {/*                <div className="device-attetion">*/}
                    {/*                    <span className="device-subtext device-subtext-red"*/}
                    {/*                          style={{fontStyle: 'italic'}}>*/}
                    {/*                        {t('Profile.Add-device-prise')} {newDeviceCost} ₽*/}
                    {/*                    </span>*/}
                    {/*                    <span className="device-subtext device-subtext-red"*/}
                    {/*                          style={{fontStyle: 'italic'}}>*/}
                    {/*                        {t('Profile.Add-device-comment')}*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <span style={{marginLeft: '0', marginBottom: '5px'}} className="block-progress-signature">{t('Profile.Signature-add')}</span>*/}

                    {/*            </div>*/}
                    {/*            /!*<div className="device-icon">*!/*/}
                    {/*            /!*<IoIosArrowForward size='80%'/>*!/*/}
                    {/*            /!*</div>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*<></>*/}
                    {/*)}*/}

                    {userData.subscriptionIsActive ? (
                        <div style={{width: '100%', marginTop: '15px'}}>
                            <div style={{width: '98%', display: 'flex', flexDirection: 'column'}}>
                                <span className="device-title">
                                    {t('Profile.Next-billing')} {userData.amount} ₽
                                </span>
                                <span className="device-subtext" style={{marginTop: '5px', fontSize: '12px', color: 'white', fontStyle: 'italic'}}>
                                    {t('Profile.Next-billing-comment')}
                                </span>
                                <span className="device-subtext button-text" style={{marginTop: '5px', fontSize: '12px', color: 'white', fontStyle: 'italic', cursor: 'pointer', opacity: '0.6'}}
                                      onClick={() => navigate('/dolor')}>
                                    {t('Profile.Cancel-subscription')}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <>
                    { deviceData.length > 0 ? (
                        <div className='device-box'>
                            <span>
                                {t('Profile.No-devices')}
                            </span> <br/>
                            <button className='button_dark' onClick={() => {navigate('/shop')}}>
                                {t('Profile.Go-store')}
                            </button>
                        </div>
                    ) : (
                        <div className="loading-spinner">
                            <PulseLoader color="#FFF" loading={true} size={10} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Profile;
