import React, { useEffect, useState } from 'react';
import '../languages/i18n';
import './PanelStyle.css';
import { toast } from "react-toastify";
import apiClient from './axiosConfig';


function PanelLogs() {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        fetchReceipts();
    }, []);

    const fetchReceipts = () => {
        const userId = localStorage.getItem('selected_person');
        const url = `https://gostlink.com/api/panel/logs?userId=${userId}`;
        apiClient.post(url)
            .then(response => {
                setUserData(response.data || []);
                console.log(userData)
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Действия пользователя</strong></span><br/>
                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal' }}>
                    {userData ? (
                        userData.length > 0 ? (
                            userData.map((log, index) => (
                                <div key={index} className="panel-section">
                                    <div style={{ textAlign: 'left' }}>
                                        <span><b>ID:</b> {log.id}</span><br />
                                        <span><b>Email:</b> {log.email}</span><br />
                                        <span><b>Устройство:</b> {log.deviceName}</span><br />
                                        <span><b>Платформа:</b> {log.platform}</span><br />
                                        <span><b>Сервер:</b> {log.serverName || 'Не указан'}</span><br />
                                        <span><b>Подключение:</b> {new Date(log.connectionDate).toLocaleString()}</span><br />
                                        <span><b>IP:</b> {log.userIp}</span><br />
                                        <span>
                                            <b>Успешно? </b>
                                        </span>
                                        <span className={`panel-block-comment panel-block-comment-accent ${log.success ? 'panel-block-comment-OK panel-block-comment-OK-sh' : 'panel-block-comment-NB panel-block-comment-NB-sh'}`}>
                                            {log.success ? 'Да' : 'Не очень'}
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>Тут ничего нет</p>
                        )
                    ) : (
                        <p>Loading data...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PanelLogs;
