import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import apiClient from './axiosConfig';

import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";


const PanelPromo = () => {
    const [promoTypes, setPromoTypes] = useState(null);
    const [selectedPromoType, setSelectedPromoType] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(20);
    const [expirationMonths, setExpirationMonths] = useState(1);
    const [responseMessage, setResponseMessage] = useState('');
    const [promoCodes, setPromoCodes] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [errors, setErrors] = useState({
        promoCode: "",
        discount: "",
        expirationMonths: "",
    });

    useEffect(() => {
        apiClient.get('https://gostlink.com/api/panel/promos/promoTypes')
            .then(response => {
                setPromoTypes(response.data);
                if (response.data.length > 0) {
                    setSelectedPromoType(response.data[0].type);
                }
            })
            .catch(error => {
                console.error('Error fetching promo types:', error);
            });
    }, []);

    useEffect(() => {
        fetchPromoCodes()
    }, [currentPage]);

    const handlePromoCodeChange = (e) => {
        const input = e.target;
        const selectionStart = input.selectionStart;
        const formattedCode = input.value.toUpperCase().replace(/[^A-Z0-9_-]/g, '');

        if (formattedCode !== promoCode) {
            setPromoCode(formattedCode);

            setTimeout(() => {
                input.setSelectionRange(selectionStart, selectionStart);
            }, 0);
        }
    };

    const validateFields = () => {
        const newErrors = {};
        if (promoCode.length < 5) {
            newErrors.promoCode = "Промокод должен быть длиной 5 или более символов.";
        }
        if (discount < 20 || discount > 50 || discount % 5 !== 0) {
            newErrors.discount = "Скидка должна быть от 20 до 50 и кратна 5.";
        }
        if (expirationMonths < 1 || expirationMonths > 12 || expirationMonths % 1 !== 0) {
            newErrors.expirationMonths = "Срок действия должен быть от 1 до 12 месяцев.";
        }
        setTimeout(() => {
            setErrors({
                promoCode: "",
                discount: "",
                expirationMonths: "",
            });
        }, 7000);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleGeneratePromo = () => {
        if (validateFields()) {
            const expirationDate = dayjs().add(expirationMonths, 'month').format('YYYY-MM-DD');
            const requestData = {
                promoCode: promoCode,
                promoType: selectedPromoType,
                isAvailable: true,
                discount: discount/100,
                expirationDate: expirationDate
            };

            apiClient.post('https://gostlink.com/api/panel/promos/generatePromo', requestData)
                .then(response => {
                    setResponseMessage({ message: response.data.message, success: response.data.success });
                    fetchPromoCodes();
                    setTimeout(() => setResponseMessage(''), 7000);
                })
                .catch(error => {
                    setResponseMessage({ message: error.response?.data?.message || 'Error generating promo code.', success: false });
                    setTimeout(() => setResponseMessage(''), 7000);
                    console.error('Error generating promo code:', error);
                });
        } else {
            setResponseMessage({ success: false, message: "Такие промокоды создавать нельзя!" });
        }
        setTimeout(() => {
            setResponseMessage("");
        }, 7000);
    };

    const fetchPromoCodes = () => {
        const url = `https://gostlink.com/api/panel/promos/availablePromos?page=${currentPage}&size=5&sortBy=id&sortDirection=DESC`;
        apiClient.get(url)
            .then(response => {
                setPromoCodes(response.data.content);
                setTotalPages(response.data.page.totalPages);
            })
            .catch(error => {
                console.error('Error fetching promo codes:', error);
            });
    }

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };



    return (
        <div className="panel-container">
            <div className="panel-block">
                <span><strong>Мир промокодов</strong></span><br/>
            </div>
            <div className="panel-block">
                <p className='panel-block-title'>Генератор промокодов</p>
                {promoTypes ? (
                    promoTypes.length > 0 ? (
                        <div className='panel-section'>
                            <div style={{textAlign: 'left'}}>
                                <label>Тип: </label>
                                <select value={selectedPromoType}
                                        onChange={(e) => setSelectedPromoType(e.target.value)}
                                >
                                    {promoTypes.map((type, index) => (
                                        <option key={index} value={type.type}>{type.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{textAlign: 'left'}}>
                                <label>Промокод: </label>
                                <input type="text"
                                       value={promoCode}
                                       onChange={handlePromoCodeChange}
                                       placeholder="Введите промокод"
                                />
                            </div>
                            {errors.promoCode && <p className='panel-block-comment panel-block-comment-NB'>{errors.promoCode}</p>}
                            <div style={{textAlign: 'left'}}>
                                <label>Скидка: </label>
                                <input type="number"
                                       min="20"
                                       max="50"
                                       step="5"
                                       value={discount}
                                       onChange={(e) => setDiscount(Number(e.target.value))}
                                />
                            </div>
                            {errors.discount && <p className='panel-block-comment panel-block-comment-NB'>{errors.discount}</p>}
                            <div style={{textAlign: 'left'}}>
                                <label>Срок действия (мес): </label>
                                <input type="number"
                                       min="1"
                                       max="12"
                                       value={expirationMonths}
                                       onChange={(e) => setExpirationMonths(Number(e.target.value))}
                                />
                            </div>
                            {errors.expirationMonths && <p className='panel-block-comment panel-block-comment-NB'>{errors.expirationMonths}</p>}
                            <p className='panel-block-comment' style={{textAlign: 'left'}}>
                                <b>Промокод</b> должен быть длинее 5 символов, может содержать только латинские буквы, знаки тире и подчеркивания<br/>
                                <b>Скидка</b> должна быть от 20 до 50 процентов и кратна 5<br/>
                                <b>Срок действия</b> от 1 до 12 месяцев<br/>
                            </p>
                            <button className="panel-button"
                                    onClick={handleGeneratePromo}>
                                Активировать промокод
                            </button>
                            {responseMessage &&
                                <p className={`panel-block-comment ${responseMessage.success ? 'panel-block-comment-OK' : 'panel-block-comment-NB'}`}>
                                    {responseMessage.message}
                                </p>
                            }
                        </div>
                    ) : (
                        <p>Промокоды пока нельзя создавать, извини(</p>
                    )
                ) : (
                    <p>Loading data...</p>
                )}

            </div>
            <div className="panel-block">
                <p className='panel-block-title'>Список активных промокодов</p>
                {promoCodes ? (
                    promoCodes.length > 0 ? (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', width: '100%', marginBottom: '10px'}}>
                                <input
                                    type="text"
                                    inputMode="text"
                                    placeholder="Введите промокод..."
                                    style={{width: '100%', margin: '0 5px 0 0'}}
                                />
                                <button className="panel-button" style={{margin: '0 0 0 5px'}}>Поиск</button>
                            </div>
                            {promoCodes.map((promo) => (
                                <div className="panel-section" key={promo.id}>
                                    <div style={{ textAlign: 'left' }}>
                                        {/*<span><b>ID: </b> {promo.id}</span><br/>*/}
                                        <span><b>Промокод: </b> {promo.promoCode}</span><br/>
                                        <span><b>Тип акции: </b> {promo.promoType}</span><br/>
                                        <span><b>Срок действия: </b> {promo.expirationDate}</span><br/>
                                        <span><b>Скидка: </b> {promo.discount * 100}%</span><br/>
                                        {/*<span><b>Доступность: </b> {promo.available ? 'Доступен' : 'Не доступен'}</span><br/>*/}
                                    </div>
                                    {/*<button className="panel-button">Заблокировать промокод</button>*/}
                                </div>
                            ))}
                            {totalPages > 1 && (
                                <div className="navigation-list" style={{ }}>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 0}
                                        style={{ opacity: currentPage === 0 ? 0.5 : 1 }}
                                    >
                                        <FaAngleLeft size={15} color='rgba(0, 0, 0, 0.7)' style={{marginRight: '2px'}}/>
                                    </button>
                                    <span>{currentPage + 1} / {totalPages}</span>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages - 1}
                                        style={{ opacity: currentPage === totalPages - 1 ? 0.5 : 1 }}
                                    >
                                        {/*<IoCaretForwardOutline/>*/}
                                        <FaAngleRight size={15} color='rgba(0, 0, 0, 0.7)' style={{marginLeft: '2px'}}/>
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>Нет активных промокодов</p>
                    )
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
        </div>
    );
};

export default PanelPromo;
