import React, {useEffect, useState} from 'react';
import '../css/App.css';
import '../languages/i18n';
import Profile from "./Profile";
import {useTranslation} from "react-i18next";
import {PulseLoader} from "react-spinners";

function ProfileVMobile() {
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');
        const uid = queryParams.get('uid');
        const photoURL = queryParams.get('photoURL');
        const providerId = queryParams.get('providerId');

        localStorage.setItem('uid', uid);
        localStorage.setItem('email', email);
        localStorage.setItem('photoURL', photoURL);
        localStorage.setItem('providerId', providerId);

        setIsLoaded(true);
    }, []);



    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {isLoaded ? (
                <Profile/>
            ) : (
                <div className="loading-spinner">
                    <PulseLoader color="#FFF" loading={true} size={10} />
                </div>
            )}
        </div>
    );
}

export default ProfileVMobile;