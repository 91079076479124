import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-PGNTHM5EBF');

if (window.location.hostname === 'gostlink.ru') {
    window.location.href = 'https://gostlink.com' + window.location.pathname + window.location.search;
} else {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );

    reportWebVitals();
}
