import React, { useState, useEffect } from 'react';
import '../css/App.css';
import '../languages/i18n';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from "./elements/User";
import { QRCodeCanvas } from 'qrcode.react';
import { PiStarFourFill } from "react-icons/pi";
import { BsStars } from "react-icons/bs";
import {ImCross} from "react-icons/im";
import {useTranslation} from "react-i18next";
import {PulseLoader} from "react-spinners";

function PromoNew() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [Refferal, setRefferal] = useState("");

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .catch((error) => {
                console.error('Failed to copy: ', error);
            });
        toast.success("Скопировано в буфер обмена");
    };

    const handleShareQr = async (shareMessage, shareUrl) => {
        try {
            const canvas = document.querySelector('.Promo-block-qr-code > canvas');
            const imageUrl = canvas.toDataURL('image/png');

            if (navigator.share) {
                await navigator.share({
                    title: 'Поделиться',
                    text: shareMessage,
                    url: shareUrl,
                    files: [new File([await fetch(imageUrl).then(r => r.blob())], 'gostlink_qr.png', { type: 'image/png' })],
                });
            } else {
                toast.error('Ваше устройство не поддерживает функцию "Поделиться".');
            }
        } catch (error) {
            if (error && error.length > 0) {
                toast.error('Ошибка при попытке поделиться:', error);
            }
        }
    };

    const handleShare = async (shareMessage, shareUrl) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Поделиться',
                    text: shareMessage,
                    url: shareUrl,
                });
            } else {
                toast.error('Ваше устройство не поддерживает функцию "Поделиться".');
            }
        } catch (error) {
            if (error && error.length > 0) {
                toast.error('Ошибка при попытке поделиться:', error);
            }
        }
    };

    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchPromo(requestData);
    }, []);

    const handleDownload = () => {
        const canvas = document.querySelector('.Promo-block-qr-code > canvas');
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'gostlink_qr.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fetchPromo = (requestData) => {
        fetch(`https://gostlink.com/api/profile/getReferralCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then(response => response.text())
            .then(text => {
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                if (data.success) {
                    localStorage.setItem('referral', data.referralCode);
                    setRefferal(data.referralCode);
                } else {
                    navigate(`/`);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    return (
        <div className="Promo-block-main-container">
            <ToastContainer toastStyle={{ color: '#ffffff', backgroundColor: '#333333' }} />
            <User />
            {Refferal.length > 10 ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="Promo-block Promo-block-title">
                        <span dangerouslySetInnerHTML={{ __html: t('Promo.Title') }} />

                        <div className="Promo-block-arrow Promo-block-arrow-II Promo-block-arrow-half"
                             style={{height: '50px', marginRight: '50%'}}></div>
                    </div>

                    <div className="Promo-block Promo-block-instruction">
                        <div className="Promo-block-number Promo-block-number-left">
                            <span>{t(`Promo.Instruction.0.Step`)}</span>
                        </div>
                        <p className="Promo-block-info-card Promo-block-info-card-left">
                            {t(`Promo.Instruction.0.Comment`)}
                        </p>

                        <div className="Promo-block-arrow Promo-block-arrow-IV Promo-block-arrow-half"></div>
                        <div className="Promo-block-arrow Promo-block-arrow-III"></div>
                    </div>

                    <div className="Promo-block Promo-block-instruction">
                        <div className="Promo-block-number Promo-block-number-right">
                            <span>{t(`Promo.Instruction.1.Step`)}</span>
                        </div>
                        <p className="Promo-block-info-card Promo-block-info-card-right">
                            {t(`Promo.Instruction.1.Comment`)}
                        </p>

                        <div className="Promo-block-arrow Promo-block-arrow-I"></div>
                        <div className="Promo-block-arrow Promo-block-arrow-II"></div>
                    </div>

                    <div className="Promo-block Promo-block-instruction">
                        <div className="Promo-block-number Promo-block-number-left">
                            <span>{t(`Promo.Instruction.2.Step`)}</span>
                        </div>
                        <p className="Promo-block-info-card Promo-block-info-card-left">
                            {t(`Promo.Instruction.2.Comment`)}
                        </p>

                        <div className="Promo-block-arrow Promo-block-arrow-IV"></div>
                        <div className="Promo-block-arrow Promo-block-arrow-III Promo-block-arrow-half"></div>
                        <div className="Promo-block-arrow Promo-block-arrow-I Promo-block-arrow-half"
                             style={{height: '50px', top: 'calc(100% - 2px)', right: 'calc(50% - 2px)'}}></div>
                    </div>

                    <div className="Promo-block Promo-block-info">
                        <div className="Promo-block-info-card Promo-block-share">
                            <div className="Promo-block-qr-code" onClick={() => handleShareQr("Сервис gostlink для безопасного и быстрого доступа в интернет ", "https://gostlink.com/?token=" + Refferal)}>
                                <QRCodeCanvas
                                    value={"https://gostlink.com/?token=" + Refferal}
                                    size={256}
                                    bgColor={"#FFFFFF"}
                                    fgColor={"#000000"}
                                    level={"H"}
                                    marginSize={4}
                                />
                            </div>
                            <div className="Promo-block-buttons">
                                <button className='Promo-block-button' onClick={() => handleShare("Сервис gostlink для безопасного и быстрого доступа в интернет ", "https://gostlink.com/?token=" + Refferal)}>
                                    {t('Promo.Button-1')}
                                </button>
                                <button className='Promo-block-button' onClick={() => copyToClipboard("https://gostlink.com/?token=" + Refferal)}>
                                    {t('Promo.Button-2')}
                                </button>
                                <button className='Promo-block-button' onClick={handleDownload}>
                                    {t('Promo.Button-3')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="loading-spinner">
                    <PulseLoader color="#FFF" loading={true} size={10} />
                </div>
            )}
        </div>
    );
}

export default PromoNew;
